<template>
  <v-dialog v-model="dialog" max-width="1000" scrollable>
    <close-button @click="closeDialog()" overflow />
    <v-card class="goal" :style="{ maxHeight: '90vh !important', overflowY: 'scroll' }">
      <Goal
        ref="goal"
        :plan="plan"
        @closeDialog="closeDialog()"
        @getId="getId"
        @addedNewMeal="addedNewMeal"
        :editNewMealplan="editNewMealplan"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import Goal from '../../user/Goal.vue'

export default {
  data() {
    return {
      dialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    plan: Object,
    editNewMealplan: Boolean
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Goal
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.dialog = true
      if(this.$refs.goal)
        this.$refs.goal.setGoal()
    },

    closeDialog() {
      this.dialog = false

      this.$emit('closeCalculator')
    },

    getId(id) {
      this.$emit('getId', id)
    },

    addedNewMeal(newData) {
      this.$emit('addedNewMeal', newData)
    }
  }
}
</script>
