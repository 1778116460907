<template>
  <v-card
    v-if="recipe"
    class="fill-height"
    outlined
    hover
  >
    <v-card-text class="pa-2">
      <div dense>
        <div class="d-flex gap-10">
          <v-card max-width="100" max-height="100" outlined tile>
            <recipe-image :recipe="recipe" size="medium" />
          </v-card>

          <div>
            <div class="subtitle-1 font-weight-bold" :style="{ lineHeight: '1.4' }">
              {{ recipe.name }}
            </div>
            <div v-if="user.energyUnit == 'cal'">{{ recipe.servingValues.energy | kjToCal | amount | numberFormat }} Cal</div>
            <div v-else>{{ recipe.servingValues.energy | amount | numberFormat }} kJ</div>

            <div v-if="recipe?.toggleCompany" class="caption">
              {{ recipe?.companyName }}
            </div>

            <div class="mt-1">
              <v-btn 
                @click="$emit('selected', recipe)"
                color="primary"
                class="mr-1"
                x-small
                depressed
              >Select</v-btn>
              
              <v-btn 
                @click="$emit('showCard', { recipe, day: cell.day, mealTime: cell.meal, meal: null })"
                color="success"
                outlined
                x-small
                text
              >View</v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import RecipeImage from '@/components/RecipeImage'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    recipe: {
      type: [Object, null],
    },
    cell: {
      type: [Object, null]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    RecipeImage,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  }
}
</script>