var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"590"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Notice ")]),_c('v-card-text',[_c('p',[_vm._v(" Please upload your logo and Hex code before generating or downloading. Find your brand hex code, by uploading your logo at "),_c('a',{attrs:{"href":"https://imagecolorpicker.com/en","target":"_blank"}},[_vm._v("https://imagecolorpicker.com/en")])])]),_c('v-card-text',[(
            _vm.user &&
            [
            'dietitian',
            'personal_trainer',
            'nutritionist',
            'naturopath',
            ].includes(_vm.user.role) &&
            !_vm.user?.isStudent
        )?_c('custom-logo',{staticClass:"step-logo mb-3",attrs:{"plans":_vm.allPlans}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Got it ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }