import 'jspdf-autotable'
import jsPDF from 'jspdf'

// import { mapState } from 'vuex'

export default {
  // computed: {
  //   ...mapState({
  //     user: state => state.user.user
  //   })
  // },
  methods: {
    downloadShoppingList() {
      return new Promise((resolve) => {
        const doc = new jsPDF()
        var rgb = null

        if (this.user.brandColor) {
          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.user.brandColor)
          rgb = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
        }

        var primaryColor = rgb || [151, 200, 31]

        let logoImg = new Image
        // let mealzeeImg = new Image

        // mealzeeImg.src = 'https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fpowered_by.png?alt=media&token=53f85656-97eb-49ba-9fe1-3389861b9b6b'

        logoImg.src = this.user.customLogo ? this.user.customLogoUrl : 'https://firebasestorage.googleapis.com/v0/b/cq-nutrition.appspot.com/o/misc%2Fmealzee_pdf.png?alt=media&token=b078269d-186b-4249-b3c1-b15b50ad041e'

        logoImg.onload = () => {
          if (this.user?.isStudent) {
            doc.autoTable({
              head: [
                [{ content: '*Student Sample Only', styles: { fontStyle: 'bold', fontSize: 12, textColor: [255, 0, 0] } }]
              ],
              theme: 'plain'
            })
          }

          if (this.$store.getters['subscriptions/isOnLow']) {
            doc.autoTable({
              head: [
                [{ content: '' }, { content: 'Powered by Mealzee' }]
              ],
              theme: 'plain',
              body: [[''], ['']],
              columnStyles: {
                0: { cellWidth: 140 },
                1: { cellWidth: 40 },
              },
              didDrawCell: function (data) {
                console.log(data)
                if (data.row.index == 0 && data.column.index == 0 && data.cell.section == 'head') {
                  doc.addImage(logoImg, 'png', (data.cell.x + 1), data.cell.y, logoImg.width / (logoImg.height / 23), 23)
                }

                // if (data.row.index == 0 && data.column.index == 1 && data.cell.section == 'head') {
                //   doc.addImage(mealzeeImg, 'png', (data.cell.x + 1),  data.cell.y, logoImg.width / (logoImg.height/25), 23)
                // }
              },
            })
          } else {
            doc.autoTable({
              head: [
                [{ content: '' }]
              ],
              theme: 'plain',
              didDrawCell: function (data) {
                console.log(data)
                if (data.row.index == 0 && data.column.index == 0 && data.cell.section == 'head') {
                  doc.addImage(logoImg, 'png', (data.cell.x + 1), data.cell.y, logoImg.width / (logoImg.height / 25), 23)
                }
              },
            })

          }

          doc.autoTable({
            head: [
              [{ content: 'Shopping List', styles: { fontStyle: 'bold', fontSize: 22, halign: 'center' } }],
            ],
            theme: 'plain'
          })

          this.tableData.forEach(data => {

            const bodyData = data.content.map(cont => {
                return [...cont.ing.map(i => ({ content: i })), {content: this.getLink(cont.id)}]
            })

            data.headers.push('Recommended Product')

            doc.autoTable({
              theme: 'grid',
              headStyles: { fillColor: primaryColor },
              styles: { fontSize: 8 },
              head: [data.headers],
              body: bodyData,
              columnStyles: {
                0: { cellWidth: 80 },
                1: { cellWidth: 20 },
                2: { cellWidth: 55 },
                3: { cellWidth: 35 },
              },
            })
          })

          doc.save("Shopping List.pdf")
          resolve('Successful')
        }
      })
    },
  }
}

