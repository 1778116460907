<template>
  <v-sheet v-if="customModel" :min-width="200" :max-width="width">
    <v-btn-toggle color="primary" mandatory style="flex-wrap: wrap">
      <v-btn v-if="!hideTags"  @click="tagView = 'meal'" x-small>Meal Tags <span v-if="customModel?.mealTags && customModel.mealTags.length" class="custom-badge">{{ customModel.mealTags.length }}</span></v-btn>
      <v-btn v-if="!hideTags" @click="tagView = 'nutrition'" x-small>Nutrition Tags <span v-if="customModel?.nutritionTags && customModel.nutritionTags.length" class="custom-badge">{{ customModel.nutritionTags.length }}</span></v-btn>
      <v-btn @click="tagView = 'ingredients'" x-small>Excluded Ingredients<span v-if="customModel?.ingredientTags && customModel.ingredientTags.length" class="custom-badge">{{ customModel.ingredientTags.length }}</span></v-btn>
    </v-btn-toggle>

    <div class="mt-7 flex-grow-1">
      <v-sheet v-if="tagView == 'meal'" max-width="590" color="transparent">
        <v-select
          :items="mealTags"
          v-model="customModel.mealTags"
          placeholder="Select meal tags"
          class="meal-tags align-start"
          v-if="mealTags.length"
          @change="update()"
          item-text="name"
          item-value="id"
          deletable-chips
          height="100"
          width="100%"
          small-chips
          multiple
          outlined
          chips
          dense
        ></v-select>
      </v-sheet>
      
      <v-sheet v-if="tagView == 'nutrition'" max-width="590" color="transparent">
        <v-select
          :items="nutritionTags"
          v-model="customModel.nutritionTags"
          placeholder="Select nutrition tags"
          class="meal-tags align-start"
          v-if="nutritionTags.length"
          @change="update()"
          item-text="name"
          item-value="id"
          deletable-chips
          height="100"
          width="100%"
          small-chips
          multiple
          outlined
          chips
          dense
        ></v-select>
      </v-sheet>
      
      <v-sheet v-if="tagView == 'ingredients'" max-width="590" color="transparent">
        <v-combobox
          placeholder="Type the ingredients you would like to exclude then press enter"
          v-model="customModel.ingredientTags"
          class="meal-tags align-start"
          @change="update()"
          deletable-chips
          height="100"
          width="100%"
          small-chips
          multiple
          outlined
          chips
          dense
        ></v-combobox>
      </v-sheet>
    </div>

    <!-- warning dialog box -->
    <v-dialog v-model="warningDialog" max-width="390">
      <v-card>
        <v-card-title class="headline">Warning</v-card-title>
        <v-card-text>
          Using more filters reduces available recipes.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="yellow darken-1" text @click="warningDialog = false">Got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    customModel: Object,
    hideTags: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '450',
    },
    tagViewProp: {
      type: String,
    }
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      tagsLimit: 2,
      tagView: 'meal',
      warningDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),

    nutritionTags: function () {
      return this.$store.getters['nutritiontags/tags']
    },

    mealTags: function () {
      return this.$store.getters['tags/tags']
    }
  },

  watch: {
    'customModel.mealTags': function (val) {
      if(val.length > 3) {
        this.warningDialog = true
      }
    },
    'customModel.nutritionTags': function (val) {
      if(val.length > 3) {
        this.warningDialog = true
      }
    },

    'customModel.ingredientTags': function (val) {
      if(val.length > 3) {
        this.warningDialog = true
      }
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    update() {
      // if (this.customModel.ingredientTags && this.customModel.ingredientTags.length > this.tagsLimit)
      //   this.customModel.ingredientTags.splice(0, 1)

      this.$store.dispatch('saveDocument', this.customModel)
    }
  },

  mounted () {
    if(this.tagViewProp) {
      this.tagView = this.tagViewProp
    }
  }
}
</script>
<style scoped>
.custom-badge {
  background-color: #f44336;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  margin-left: 5px;
  line-height: 1.4;
  height: 14px;
  width: 14px;
}
</style>