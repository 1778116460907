<template>
    <v-dialog
        v-model="dialog"
        max-width="590"
    >
        <v-card>
        <v-card-title class="headline">
            Notice
        </v-card-title>
    
        <v-card-text>
            <p>
                Please upload your logo and Hex
                code before generating or downloading. Find your brand hex code, by uploading your logo at 
                <a href="https://imagecolorpicker.com/en" target="_blank">https://imagecolorpicker.com/en</a> 
            </p>
        </v-card-text>
        <v-card-text>
            <custom-logo
            v-if="
                user &&
                [
                'dietitian',
                'personal_trainer',
                'nutritionist',
                'naturopath',
                ].includes(user.role) &&
                !user?.isStudent
            "
            class="step-logo mb-3"
            :plans="allPlans"
            />
        </v-card-text>
    
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="primary"
            text
            @click="dialog = false"
            >
            Got it
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import CustomLogo from './CustomLogo.vue'
export default {
    name: 'WarningDialog',
    data: () => ({
        dialog: false
    }),
    props: {
        user: Object,
        allPlans: Array
    },
    components: {
        CustomLogo
    },
    methods: {
        openDialog() {
            this.dialog = true
        },
        closeDialog() {
            this.dialog = false
        }
    }
}
</script>

<style>

</style>